.trade-details {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-direction: row-reverse;

  .trade-details__header {
    // width: 24px;
    background: #d3e6e6;
    border-radius: 0px 5px 5px 0px;
    writing-mode: vertical-rl;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: rgba($color: #184858, $alpha: 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    padding: 10px 7px;

    .trade-details-header__rollout,
    .trade-details-header__rollout_hover {
      width: 8px;
      height: 24px;
    }

    .trade-details-header__rollout_hover {
      display: none;
      opacity: 0.4;
    }

    .trade-details-header__rollout {
      opacity: 0.15;
    }

    .trade-details-header__details {
      display: flex;
      justify-content: center;
      align-items: center;

      & > span {
        margin: 8px 0;
      }

      .trade-details-header__arrow,
      .trade-details-header__arrow_hover {
        width: 10px;
        height: 10px;
      }

      .trade-details-header__arrow_hover {
        display: none;
      }

      .trade-details-header__arrow {
        opacity: 0.4;
      }
    }

    &:hover,
    &:focus,
    &:active {
      padding: 10px 15px;
      background: #8fcccc;
      color: #ffffff;

      .trade-details-header__rollout_hover {
        display: block;
      }

      .trade-details-header__rollout {
        display: none;
      }

      .trade-details-header__details {
        .trade-details-header__arrow_hover {
          display: block;
        }

        .trade-details-header__arrow {
          display: none;
        }
      }
    }
  }

  .trade-details__body {
    height: 100%;
    min-width: 300px;
    background: #e4f0f0;
    border-radius: 0px;
    padding: 25px;
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    position: relative;
  }

  // 打开
  &.trade-details__open {
    .trade-details__header {
      padding: 10px 15px;
      background: #8fcccc;
      color: #ffffff;

      .trade-details-header__rollout_hover {
        display: block;
      }

      .trade-details-header__rollout {
        display: none;
      }

      .trade-details-header__details {
        .trade-details-header__arrow_hover {
          display: block;
        }

        .trade-details-header__arrow {
          display: none;
        }
      }

      .trade-details-header__details {
        .trade-details-header__arrow,
        .trade-details-header__arrow_hover {
          transform: rotate(180deg);
        }
      }
    }
  }
}
