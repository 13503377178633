.switch-network-modal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      padding: 20px 16px 16px 16px;
      border-radius: 10px;
      border-bottom: none;
      .ant-modal-title {
        font-size: 24px;
        font-weight: bolder;
      }
    }
    .ant-modal-body {
      padding: 10px 16px;
      font-size: 16px;
      .content {
        padding-bottom: 10px;
        margin: 0;
      }
      .description {
        font-style: italic;
        margin: 0;
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-top: none;
      .ant-btn {
        border-radius: 30px;
        padding-left: 20px;
        padding-right: 20px;
        border-color: #1890ff;
        color: #1890ff;
      }
    }
  }
}
