.global-config-modal {
  width: 400px !important;
  position: absolute;
  right: 10px;
  top: 61px;
  .ant-modal-close-x {
    line-height: 46px;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .global-config-arrow {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    background: 0 0;
    border-style: solid;
    border-width: 8px;
    right: 25px;
    transform: translate(-50%, -50%) rotate(45deg);
    border-color: white;
  }
  .global-config-container {
    .global-config-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 16px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
      position: relative;
      border-radius: 5px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        font-weight: bold;
        color: #184858;
      }
    }
    .global-config-body {
      background: #ffffff;
      padding-left: 24px;
      .global-config-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 16px;
        border-bottom: 1px solid #f1f1f1;
        label {
          font-size: 12px;
          color: rgba($color: #184858, $alpha: 0.6);
          display: flex;
          align-items: center;
        }
        .global-config-inputs {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex: 1;
          .ant-radio-group {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .ant-radio-button-wrapper {
              width: 56px;
              height: 24px;
              font-size: 11px;
              font-weight: bold;
              background: #ffffff;
              border: 1px solid #f1f1f1;
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
              border-radius: 2px;
              margin-left: 8px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover,
              &:focus,
              &:active {
                background: #00bfbf;
                color: #ffffff;
              }
              &::before {
                background-color: #00bfbf;
                width: 0;
              }
              &.ant-radio-button-wrapper-checked {
                background: #00bfbf;
                color: #ffffff;
              }
            }
          }
          .ant-input-affix-wrapper {
            margin-left: 24px;
            width: 80px;
            height: 24px;
            background: #ffffff;
            border: 1px solid #00bfbf;
            border-radius: 2px;
            font-size: 11px;
            input {
              color: #184858;
            }
            .ant-input-suffix {
              color: #00bfbf;
            }
          }
        }
      }
    }
  }
  .tooltip-info-icon {
    color: #ffffff;
    background: #184858;
    font-size: 10px;
    border: solid 1px #184858;
    border-radius: 11px;
    opacity: 0.6;
  }
}
