.trade-detail-card_position-detail {
  padding-top: 20px;

  .ant-card-head {
    border-bottom: 0;
    padding: 0 30px;

    .ant-card-head-title {
      padding: 34px 0;

      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  .ant-card-body {
    padding: 0 30px;
  }

  table > tbody > tr.ant-descriptions-row > td {
    padding-bottom: 6px;
  }

  .position-detail__table {
    padding: 0;
    padding-bottom: 19px;
    margin-bottom: 19px;

    &.position-detail__table_diff {
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    & > div {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      dt {
        color: #184858;
        font-size: 11px;
        opacity: 0.6;
      }

      dd {
        margin-bottom: 0;
        color: #184858;
        font-size: 11px;
        font-weight: bold;
      }
    }
  }

  .tooltip-info-icon {
    color: #ffffff;
    background: #184858;
    font-size: 10px;
    border: solid 1px #184858;
    border-radius: 11px;
    opacity: 0.6;
  }

  &.trade-card-position-detail_diff {
    .ant-card-head {
      .ant-card-head-title {
        padding: 0;
        padding-top: 34px;
        padding-bottom: 20px;
      }
    }
  }
}
