// Color
$primary-color: #00bfbf;
$secondary-color: rgba(
  $color: #184858,
  $alpha: 0.4,
);
$page-color: #f0f5f5;
$line-color: #f1f1f1;
$link-color: $primary-color;
$link-hover-color: lighten($primary-color, 10%);
$active-color: lighten($primary-color, 10%);

// Text Color
$text-color-default: rgba(0, 0, 0, 0.65);
$text-color-primary: rgba(0, 0, 0, 0.85);
$text-color-secondary: rgba(0, 0, 0, 0.45);
$text-color-disable: rgba(0, 0, 0, 0.25);

// Status Color
$success-color: #66bb44;
$warning-color: #faad14;
$error-color: #cc3300;
$important-color: #cc3300; // 重要提醒
$increase-color: #cc3300; // 增长
$decline-color: #66bb44; // 下降

$disable-color: rgba(0, 0, 0, 0.25);

// Component Color
$chunk-color: #f3f4f7; // 块颜色
$card-header-bg: #f3f4f7;
$list-hover-bg: #f3f4f7;

// Dimension
$layout-width: 1180px;

$dimension-xlg: 32px;
$dimension-lg: 24px;
$dimension-md: 16px;
$dimension-sm: 8px;

$padding-xlg: $dimension-xlg;
$padding-lg: $dimension-lg;
$padding-md: $dimension-md;
$padding-sm: $dimension-sm;

$margin-xlg: $dimension-xlg;
$margin-lg: $dimension-lg;
$margin-md: $dimension-md;
$margin-sm: $dimension-sm;

$border-radius-base: 4px;
$border-radius-sm: 2px;

// Component Size
$card-header-height: 40px;
$card-header-height-sm: 32px;
$card-padding: 8px;
$card-padding-sm: 6px;
$card-padding-lg: 16px;

$modal-header-height: 56px;
$modal-footer-height: 52px;
$modal-padding-left: 24px;
$modal-padding-right: 16px;

// Font Size
$font-size-base: 14px;
$font-size-xlg: $font-size-base + 6px;
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$font-size-xs: 10px;

// Icon Size
$icon-size: 16px;
$icon-size-lg: 24px;
$icon-size-xlg: 48px;

// ZIndex
$zindex-header: 1000;
