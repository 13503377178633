.tooltip-info-icon {
  margin-left: 2px;

  color: #ffffff;
  background: #184858;
  font-size: 10px;
  border: solid 1px #184858;
  border-radius: 11px;
  opacity: 0.6;
}

.popover-info-icon {
  margin-left: 2px;
  color: #ffffff;
  background: #ffffff;
  font-size: 12px;
  border: solid 1px #184858;
  border-radius: 12px;
  opacity: 0.6;

  svg {
    :last-child {
      color: #000000;
    }
  }
}

.icon-coin {
  width: 16px;
  height: 16px;
  // margin-top: -3px;
  margin-right: 7px;
}
