.trade-margin-card_additional-margin {
  padding-top: 20px;
  position: absolute;
  bottom: 10px;
  width: 250px;
  .margin-detail__table {
    dl {
      dd {
        cursor: pointer;
      }
    }
  }
}
