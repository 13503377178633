.trade-page {
  .trade-entry {
    .trade-entry-form {
      .ant-form-item {
        .ant-col {
          &.ant-form-item-control {
            .ant-form-item-explain {
              &.ant-form-item-explain-warning,
              &.ant-form-item-explain-validating {
                margin-top: 4px;
                div {
                  width: auto;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.trade-entry-form {
  position: relative;
  // 当前仓位
  .trade-form__current-position {
    margin-bottom: 18px;
    font-family: Helvetica;
  }
  // 输入框
  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-label {
      label {
        font-weight: bold;
        color: #184858;
        font-size: 12px;
      }
    }

    .ant-form-item-control {
      .ant-form-item-control-input {
        min-height: 48px;
        margin-right: 0px;
        .ant-input-group-wrapper {
          .ant-input-group-addon {
            width: 96px;
            background: #fbfbfb;
            border-color: #e6e6e6;
            font-size: 12px;
            color: rgba($color: #184858, $alpha: 0.6);
          }
        }

        .ant-input-affix-wrapper {
          height: 48px;
          border-color: #e6e6e6;
          // padding: 4px 0 4px 16px;
          border-right: none;

          input.ant-input {
            font-weight: bold;
            &::placeholder {
              font-weight: 400;
            }
          }

          &.ant-input-affix-wrapper-disabled {
            box-shadow: none;
            border: 1px solid #f1f1f1;
            background: #ffffff;
          }
        }
        .ant-input-suffix {
          font-weight: bold;
          color: #184858;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .ant-form-item-explain {
        // display: none;

        &.ant-form-item-explain-error,
        &.ant-form-item-explain-warning,
        &.ant-form-item-explain-validating {
          display: block;
          div {
            width: 100%;
            line-height: 20px;
            min-height: 40px;
            overflow: hidden;
          }
        }

        &.ant-form-item-explain-success {
          div {
            min-height: 40px;
          }
        }
      }
      .ant-form-item-explain-warning,
      .ant-form-item-explain-error {
        color: #faad14;
      }
    }
  }

  //quantity & suffix and trade-button
  .trade-form-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .trade-form__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-weight: normal;
      }
    }

    .trade-input-wrap {
      flex: 1;
    }

    .trade-form-wrap-btn {
      width: 150px;
      .trade-form__btn {
        display: flex;
        min-width: 18px;
        height: 48px;
        background: #00bfbf;
        border-radius: 0 3px 3px 0;
        border: solid 1px #00bfbf;
        font-size: 16px;
        padding: 0px;
        font-weight: bold;
        color: #ffffff;
        align-items: center;
        justify-content: center;
        & > img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          margin-top: 2px;
        }
        &:hover,
        &:focus,
        &:active {
          background: #00cccc;
          border-color: #00cccc;
          box-shadow: 0px 10px 20px 0px rgba(0, 204, 204, 0.3);
        }
      }
    }
  }

  .trade-simulation-wrap {
    padding: 4px 0;
    min-height: 20px;
    line-height: 20px;
    display: flex;
    .trade-simulation-content {
      font-size: 14px;
      font-weight: 400;
      color: #184858;
      opacity: 0.6;

      &.trade-simulation-content__warning {
        color: #faad14;
        opacity: 0.9;
      }
      &.trade-simulation-content__success {
        color: #009900;
        opacity: 0.9;
      }
    }
  }

  // 价格与交易按钮
  .trade-form__price-btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 16px;
    .trade-form__price-wrap {
      line-height: 1;
      display: flex;

      .trade-form__price-title {
        font-size: 24px;
        font-weight: bold;
        color: #184858;
        line-height: 30px;
        margin-right: 8px;
      }
      .trade-form__price-number {
        font-size: 28px;
        font-family: 'DINPro-Medium';
        font-weight: bold;
        color: #00bfbf;
        cursor: default;
      }

      .trade-form__price-tooltip {
        display: flex;
        margin-left: 4px;
        justify-content: center;
        align-items: center;
      }
    }
    .trade-form__difficulty-wrap {
      .trade-form__price-difficulty {
        dl {
          display: flex;
          flex-direction: row;
          font-size: 14px;
          dt {
            font-size: 14px;
          }
          dd {
            margin-left: 5px;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

// 关联信息，limit price\Price Impact\Liquidity Provider Fee
.trade-form__relation {
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 16px;
  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    dt {
      color: rgba($color: #184858, $alpha: 0.6);
    }
    dd {
      color: #184858;
    }
  }
}

.hide {
  display: none;
}
