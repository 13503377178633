@font-face {
  font-family: 'DINPro-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/DINPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'DINPro-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('DINPro-Bold'), url('../../assets/fonts/DINPro-Bold.woff') format('woff');
}
