$long-color: #52cc8f;
$short-color: #ff9999;

.trade-tabs {
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  .trade-tab {
    height: 45px;
    background: #f8f8f8;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: rgba($color: #184858, $alpha: 0.6);
    cursor: pointer;
    position: relative;
    // transition: all 0.3s;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    &::before,
    &::after {
      display: block;
      content: ' ';
      position: absolute;
      top: 0;
      height: 100%;
      width: 40px;
    }
    &::before {
      right: -25px;
      transform: skew(30deg, 0deg);
      border-radius: 0 10px 0 0;
    }
    &::after {
      left: -25px;
      transform: skew(-30deg, 0deg);
      border-radius: 10px 0 0 0;
    }
    &:first-child {
      border-radius: 10px 0 0px 0px;
    }
    &:last-child {
      border-radius: 0 10px 0px 0px;
    }
    &.trade-tab_current {
      color: #ffffff;
      height: 48px;
    }
  }
  &.trade-tabs_short {
    border-bottom: 2px solid $short-color;
    .trade-tab {
      &.trade-tab_current {
        background: $short-color;
        border-radius: 0px 10px 0px 0px;
        z-index: 2;
        &::before {
          display: none;
        }
        &::after {
          background: $short-color;
        }
      }
    }
  }
  &.trade-tabs_long {
    border-bottom: 2px solid $long-color;
    .trade-tab {
      &.trade-tab_current {
        background: $long-color;
        border-radius: 10px 0px 0px 0px;
        z-index: 2;
        &::before {
          background: $long-color;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
