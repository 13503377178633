/**
 * **global.scss**
 */
html,
body {
  background-color: #fbfbfb;
  font-size: 14px;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  #app {
    width: 100%;
    height: 100%;
  }

  div,
  form,
  img,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  option,
  td,
  th,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside none;
  }
}
