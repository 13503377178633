.ant-notification-notice {
  width: 398px;
  background: #1f2733;
  color: #ffffff;
  opacity: 0.9;
  border-radius: 5px;
  padding: 16px 12px;

  .ant-notification-notice-message {
    font-size: 16px;
    font-family: Helvetica;
    font-weight: bold;
  }

  .ant-notification-notice-close,
  .ant-notification-notice-message,
  .ant-notification-notice-description {
    color: #ffffff;
  }
}
