@import '../theme/variable';

html,
body {
  background-color: #f0f5f5 !important;
}

#root {
  .app {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    overflow-x: hidden;
    background: white;
    min-width: 960px;

    .header {
      width: 100%;
      justify-content: space-between;
    }

    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 10;
      background: #fbfbfb;
      z-index: 1;
      background: #f0f5f5;
    }
  }
}

.syn-link {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}

.syn-bold-1 {
  font-size: 12px;
  font-weight: bold;
  color: #184858;
}

/*buttons*/
.button-wrap {
  background: #00bfbf;
  border: solid 1px #00bfbf;
  border-radius: 5px;

  &:active,
  &:focus,
  &:hover {
    background: #00cccc;
    border: solid 1px #00cccc;
  }

  &:disabled {
    background: #d9d9d9;
    border-color: #d9d9d9;
    box-shadow: none;

    .button-inner {
      color: #ffffff;
    }
  }

  &.padded {
    padding: 0 4px;
  }

  &.button-white {
    background: transparent;
    border: 1px solid #e6e6e6;
    box-shadow: none;

    &:hover {
      background: transparent;
      border: solid 1px #00cccc;
    }
  }

  &.button-wrap-gray {
    background: #607580ff;
    border-color: #607580ff;
    color: #ffffff;

    &:hover {
      background: #607580ff;
      border-color: #607580ff;
    }
  }

  .button-normal {
    font-size: 13px;
    font-family: 'Helvetica';
    font-weight: 400;
    color: #ffffff;
    display: flex;

    .button-suffix-icon {
      font-size: 14px;
    }
  }

  .button-inner {
    font-size: 13px;
    font-family: 'Helvetica';
    font-weight: bold;
    color: rgb(24, 72, 88);
    display: flex;
    justify-content: center;

    &.white {
      color: #fff;
    }
  }
}
