.my-pools {
  .button-settle {
    color: rgba(0, 0, 0, 0.5);

    button:disabled {
      background: #00bfbf;
      border-color: #00bfbf;
    }

    .ant-btn-loading-icon {
      text-align: center;
      display: inline-block;
      .anticon {
        padding-right: 0px;
      }

      .anticon-loading {
        &.anticon-spin {
          color: white;
        }
      }
    }
  }
}
