body {
  .ant-steps-item-icon {
    .ant-steps-icon {
      top: -1px;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #00bfbf !important;
    box-shadow: 0px 3px 6px 0px rgba(0, 191, 191, 0.15);
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: #00bfbf !important;
    box-shadow: 0px 3px 6px 0px rgba(0, 191, 191, 0.15);
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: #ffffff;
  border-color: #00cccc;
}

/*
  ant table style global overwritting
  */
.ant-table-wrapper {
  background: #ffffff;
}

.ant-table-thead > tr > th {
  color: #ffffff !important;
  font-size: 14px;
  // background: linear-gradient(0deg, #45c9cf 0%, #49b1cb 100%) !important;
  background: #00bfbf !important;
}

.ant-table-cell:first-child {
  padding: 12px 0px 12px 18px !important;
}

/*
ant card style
*/
.ant-card-head-title {
  font-size: 16px;
  font-family: Helvetica;
  font-weight: bold;
  color: #184858;
  border: none;
}

/*
  ant-select
*/
div.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

/*
 ant-pagination
*/

.ant-pagination {
  &.mini {
    .ant-pagination-prev {
      margin: 0 18px;
      border: 1px dashed #d9d9d9;
    }

    .ant-pagination-next {
      margin-left: 10px;
      border: 1px dashed #d9d9d9;
    }

    .ant-pagination-item {
      border: none;
      margin-right: 8px;

      a {
        padding-top: 1px;
        // font-size: 16px;
        font-family: Helvetica;
        font-weight: 400;
        color: #184858;
      }

      &.ant-pagination-item-active {
        background: #00bfbf;
        border-radius: 5px;

        a {
          font-weight: bold;
          color: #ffffff;
        }
      }
    }

    .ant-pagination-total-text {
      flex: 1;
      padding-left: 18px;
      opacity: 0.6;
    }

    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/*
 ant input
*/
.ant-input-affix-wrapper {
  height: 40px;
}


/*
ant drop down
*/

.contract-select__dropdown {
  padding-bottom: 0;

  .ant-select-item {
    .ant-select-item-group {
      padding: 0;
      border-bottom: 1px solid #f1f1f1;
    }
  }

  .bloc-height-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    height: 40px;
    border-bottom: 1px solid #f1f1f1;
    color: #184858;
    font-size: 12px;
    opacity: 0.6;
  }

  .ant-select-maturity-item {
    .ant-select-item-option-content {
      div {
        // width: 100%;
        // text-align: center;
        text-indent: 10px;
      }
    }
  }

  .ant-select-item-option {
    line-height: 1;
    padding: 14px;
    font-size: 12px;
    color: #184858;
    text-align: center;
    font-family: Helvetica;

    &.ant-select-item-option-selected {
      background-color: rgba($color: #00bfbf, $alpha: 0.06);
    }

    &.ant-select-maturity-item {
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 20px;
      }
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      text-align: center;

      .bloc-height-list-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .contract-select__dropdown__coin-item {
        // transform: translate(20%, 0);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;
        // width: 50px;
        &.quote-list {
          justify-content: center;
        }
      }

      .icon-oracle {
        display: block;
      }
    }
  }

  .contract-select__base-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    border-bottom: 1px solid #f1f1f1;

    .contract-select__base-group-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      color: #184858;
    }
  }

  .ant-divider {
    margin: 0;
    margin-bottom: 0;
  }
}
