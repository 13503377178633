.trade-details-card {
  // padding: 25px;
  line-height: 1;
  .trade-details-card-header {
    margin-bottom: 16px;
    & > .trade-details-card-header__icon {
      width: 14px;
      height: 14px;
      margin-right: 9px;
    }
    font-size: 14px;
    font-weight: bold;
    color: #184858;
  }
  .trade-details-card-body {
    dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      cursor: default;
      dt {
        font-size: 12px;
        font-weight: bold;
        color: rgba($color: #184858, $alpha: 0.6);
      }
      dd {
        font-size: 12px;
        font-weight: bold;
        color: #184858;
      }
    }
  }

  &.trade-details-card_contract-detail {
    padding-bottom: 30px;
    &.contract-detail_diff {
      padding-bottom: 0px;
    }
  }

  &.trade-detail-card_position-detail {
    padding-bottom: 40px;
  }
}
